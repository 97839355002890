import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 93vh;
  gap: 1rem;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  h1 {
    color: #3b4151;
    font-size: 1.9rem;
    border-bottom: 1px solid #ebe3e3;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }

  & > div:last-child {
    width: 100%;
    padding: 5px;
    flex-grow: 1;
    overflow-y: auto;
    height: 100%;

    font-size: 13px;
    line-height: 20px;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8f8a8a;
    border-radius: 8px;
    outline: 1px solid #f7f7f7;
  }
`;
