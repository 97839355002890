import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100%;
  padding: 20px 20px;
`;

export const Title = styled.h1``;

export const Container = styled.div``;
