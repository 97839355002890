import styled from "styled-components";

export const WebhookContainer = styled.div`
  padding: 20px;
  height: 100%;
  width: 100%;
  max-width: 1440px;

  & > img {
    border-radius: 6px;
  }
`;

export const Title = styled.h1`
  color: #3b4151;
  font-size: 1.9rem;
  margin: 0;
  margin-bottom: 0.5rem !important;
  border: none !important;
`;

export const Subtitle = styled.h2`
  color: #3b4151;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

export const Container = styled.div`
  margin-bottom: 1rem;
`;

export const Divider = styled.hr`
  border: none;
  border-bottom: 1px solid #ebe3e3;
  margin: 12px 0;
`;

export const TextUp = styled.span`
  font-size: 12px;
  margin-left: 0.3rem;
`;
export const Section = styled.div`
  border: 1px solid #3b4151;
  border-radius: 0.3rem;
  padding: 1rem 1rem;
  background-color: #f7f7f7;
  margin: 1rem 0;
`;
