export const ocurrencePayload = {
  id: "number",
  name: "string",
  type: "string",
  manuallyOpened: "boolean",
  dueDate: "Date",
  assetId: "number",
  sysStatusId: "number",
  cost: "number",
  comments: "string",
  tenantId: "number",
  tenantEnabled: "boolean",
  createdAt: "Date",
  createdBy: "string",
  updatedAt: "Date",
  updatedBy: "string",
  closedAt: "Date",
  closedBy: "string",
  deletedAt: "Date",
  deletedBy: "string",
  extra: "object",
  validAnalysis: "boolean",
  hadIntervention: "boolean",
  category: "string",
  exclusionReason: "string",
  updatedby: "string",
  closedby: "string",
  deletedby: "string",
  status: "string",
  criticality: "string",
  companyId: "number",
  companyName: "string",
  facilityId: "number",
  facilityName: "string",
  evidences: [],
  diagnostics: [
    {
      description: "string",
      recommendation: "string",
      comments: "string",
    },
  ],
  assetDetails: {
    id: "number",
    comments: "string",
    facilityId: "number",
    info: "object",
    tenantId: "number",
    tenantEnabled: "boolean",
    createdAt: "string",
    createdBy: "string",
    updatedAt: "string",
    updatedBy: "string",
    deletedAt: "Date",
    deletedBy: "string",
    imageUrl: "string",
    voltage: "number",
    rotation: "number",
    powerFactor: "number",
    name: "string",
    tag: "string",
    type: "string",
    model: "string",
    manufacturer: "string",
    sector: "string",
    lifetime: "string",
    role: "string",
    problem: "string",
    sysAssetTypeId: "number",
    facilitySectorId: "number",
    order: "string",
    mtbf: "string",
    mttr: "number",
    lastIntervention: "Date",
    reliability: { riskValue: "number", alertValue: "number" },
    risk: "number",
    motorPower: "number",
    criticality: "string",
    operationStatus: "string",
    price: "string",
    sysStatusId: "number",
    analysisOwner: "string",
    customTabs: "object",
    statusOperationDateInterval: "object",
    assetTypeId: "number",
    facilityName: "string",
    sectorName: "string",
    companyId: "number",
    companyName: "string",
    logoUrl: "string",
    representativeId: "number",
    theme: {
      iconsColor: "string",
      navbarColor: "string",
    },
    assetCustomFields: "object",
    monitoringTypes: "Array<number>",
    brokenOccurrenceId: "number",
    isLightOffline: "boolean",
  },
};

export const ocurrenceUpdatedPayload = {
  id: "number",
  name: "string",
  type: "string",
  manuallyOpened: "boolean",
  dueDate: "Date",
  assetId: "number",
  sysStatusId: "number",
  cost: "number",
  comments: "string",
  tenantId: "number",
  tenantEnabled: "boolean",
  createdAt: "Date",
  createdBy: "string",
  updatedAt: "Date",
  updatedBy: "string",
  closedAt: "Date",
  closedBy: "string",
  deletedAt: "Date",
  deletedBy: "string",
  extra: "object",
  validAnalysis: "boolean",
  hadIntervention: "boolean",
  category: "string",
  exclusionReason: "string",
  updatedby: "string",
  closedby: "string",
  deletedby: "string",
  status: "string",
  criticality: "string",
  companyId: "number",
  companyName: "string",
  facilityId: "number",
  facilityName: "string",
  evidences: [],
  diagnostics: [
    {
      description: "string",
      recommendation: "string",
      comments: "string",
    },
  ],
  previousChangeData: "occurrence data changed",
  assetDetails: {
    id: "number",
    comments: "string",
    facilityId: "number",
    info: "object",
    tenantId: "number",
    tenantEnabled: "boolean",
    createdAt: "string",
    createdBy: "string",
    updatedAt: "string",
    updatedBy: "string",
    deletedAt: "Date",
    deletedBy: "string",
    imageUrl: "string",
    voltage: "number",
    rotation: "number",
    powerFactor: "number",
    name: "string",
    tag: "string",
    type: "string",
    model: "string",
    manufacturer: "string",
    sector: "string",
    lifetime: "string",
    role: "string",
    problem: "string",
    sysAssetTypeId: "number",
    facilitySectorId: "number",
    order: "string",
    mtbf: "string",
    mttr: "number",
    lastIntervention: "Date",
    reliability: { riskValue: "number", alertValue: "number" },
    risk: "number",
    motorPower: "number",
    criticality: "string",
    operationStatus: "string",
    price: "string",
    sysStatusId: "number",
    analysisOwner: "string",
    customTabs: "object",
    statusOperationDateInterval: "object",
    assetTypeId: "number",
    facilityName: "string",
    sectorName: "string",
    companyId: "number",
    companyName: "string",
    logoUrl: "string",
    representativeId: "number",
    theme: {
      iconsColor: "string",
      navbarColor: "string",
    },
    assetCustomFields: "object",
    monitoringTypes: "Array<number>",
    brokenOccurrenceId: "number",
    isLightOffline: "boolean",
  },
};
