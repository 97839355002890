import styled from "styled-components";

export const AuthContainer = styled.div`
  max-width: 1440px;
  padding: 20px;
  height: 90%;

  & > img {
    border-radius: 6px;
  }
`;

export const Title = styled.h1`
  color: #3b4151;
  font-size: 1.9rem;
  margin-bottom: 2rem;
`;

export const Container = styled.div`
  margin-bottom: 1rem;
`;
