import {
  WebhookContainer,
  Container,
  Title,
  Section,
  Divider,
  TextUp,
} from "./styles";
import ReactJson from "react-json-view";
import { ocurrencePayload, ocurrenceUpdatedPayload } from "./webhooksPayload";
const JsonView = ({ src }: { src: object }) => {
  return (
    <ReactJson
      src={src}
      collapsed={true}
      enableClipboard={false}
      displayDataTypes={false}
      name={"Payload Enviado"}
      indentWidth={2}
    />
  );
};

export const WebHooks = () => {
  return (
    <WebhookContainer>
      <Title>Webhooks</Title>
      <Divider />
      <Container>
        <label>
          Para utilizar os Webhooks do Retina, é preciso registrar o webhook. O
          registro do webhook pode ser realizado no próprio Retina, seguindo os
          passos abaixo:
          <br />
          <br />
          Meu Perfil &gt; Configurações &gt; Integração &gt; Gatilhos
          <Divider />
          <br />
          <Title>
            occurrences
            <TextUp>Gatinhos de ocorrências</TextUp>
          </Title>
          <Divider />
          <Section>
            <h2>
              Ocorrência Criada <TextUp>(OCCURRENCE_CREATED)</TextUp>
            </h2>
            <br />
            <p>Webhook acionado quando uma ocorrência é criada</p>
            <br />
            <JsonView src={ocurrencePayload} />
          </Section>
          <Section>
            <h2>
              Ocorrência Atualizada <TextUp>(OCCURRENCE_UPDATED)</TextUp>
            </h2>
            <br />
            <p>Webhook acionado quando uma ocorrência é atualizada</p>
            <br />
            <JsonView src={ocurrenceUpdatedPayload} />
          </Section>
          <Section>
            <h2>
              Ocorrência Deletada <TextUp>(OCCURRENCE_DELETED)</TextUp>
            </h2>
            <br />
            <p>Webhook acionado quando uma ocorrência é excluída</p>
            <br />
            <JsonView src={ocurrencePayload} />
          </Section>
          <Section>
            <h2>
              Ocorrência Fechada <TextUp>(OCCURRENCE_CLOSED)</TextUp>
            </h2>
            <br />
            <p>Webhook acionado quando uma ocorrência é fechada</p>
            <br />
            <JsonView src={ocurrencePayload} />
          </Section>
          <Section>
            <h2>
              Ocorrência Reaberta <TextUp>(OCCURRENCE_REOPENED)</TextUp>
            </h2>
            <br />
            <p>
              Webhook acionado quando uma ocorrência é reaberta ou restaurada
            </p>
            <br />
            <JsonView src={ocurrencePayload} />
          </Section>
        </label>
      </Container>
    </WebhookContainer>
  );
};
